@import url(https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.card-body {
  overflow: hidden;
}

.card {
  margin-bottom: 16px;
}


.world-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  -webkit-user-select: none;
  
     -moz-user-select: none;
  
      -ms-user-select: none;
  
          user-select: none;
}

  .block-container {
    margin-top: 32px;
  }

    .block-container .container-row {
      display: flex;
    }

      .block-container .container-row .container-column {
        display: flex;
        flex-direction: column-reverse;
        width: 64px;
      }

.hook-container {
  display: flex;
  flex-direction: column;
}

  .hook-container .hook {
    z-index: 1;
  }

.block {
  width: 64px;
  height: 96px;
  margin-top: -32px;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transition: margin 0.3s;
  transition: margin 0.3s;
}

.block-hoverable:hover {
  cursor: pointer;
  margin-top: -28px;
  margin-bottom: -4px;
}

.block-green {
  -webkit-filter: hue-rotate(60deg);
          filter: hue-rotate(60deg);
}

.block-red {
  -webkit-filter: hue-rotate(325deg);
          filter: hue-rotate(325deg);
}

.block-yellow {
  -webkit-filter: hue-rotate(10deg);
          filter: hue-rotate(10deg);
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
}

.block-blue {
  -webkit-filter: hue-rotate(170deg);
          filter: hue-rotate(170deg);
}

.block-original {
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
}

.selector-block {
  flex-direction: row;
  max-width: 64px;
  opacity: 0.2;
  -webkit-filter: saturate(0);
          filter: saturate(0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

  .selector-block:hover {
    -webkit-filter: saturate(1);
            filter: saturate(1);
    opacity: 1;
  }

  .selector-block .block {
    opacity: 0.5;
    image-rendering: unset;
  }

