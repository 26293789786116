.world-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  user-select: none;
}

  .block-container {
    margin-top: 32px;
  }

    .block-container .container-row {
      display: flex;
    }

      .block-container .container-row .container-column {
        display: flex;
        flex-direction: column-reverse;
        width: 64px;
      }
