.block {
  width: 64px;
  height: 96px;
  margin-top: -32px;
  image-rendering: pixelated;
  transition: opacity 0.3s;
  transition: margin 0.3s;
}

.block-hoverable:hover {
  cursor: pointer;
  margin-top: -28px;
  margin-bottom: -4px;
}

.block-green {
  filter: hue-rotate(60deg);
}

.block-red {
  filter: hue-rotate(325deg);
}

.block-yellow {
  filter: hue-rotate(10deg);
  filter: brightness(1.3);
}

.block-blue {
  filter: hue-rotate(170deg);
}

.block-original {
  filter: hue-rotate(0deg);
}
