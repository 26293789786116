.selector-block {
  flex-direction: row;
  max-width: 64px;
  opacity: 0.2;
  filter: saturate(0);
  transition: 0.5s;
}

  .selector-block:hover {
    filter: saturate(1);
    opacity: 1;
  }

  .selector-block .block {
    opacity: 0.5;
    image-rendering: unset;
  }
